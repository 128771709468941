<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="8">
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="5" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="7" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                  <div v-if="expired">
                    <v-card-title class="text-center justify-center">Session expirée</v-card-title>

                    <v-card-text class="text-center">Session expirée, veuillez réessayer </v-card-text>
                  </div>
                  <div v-else-if="isLoading">
                    <v-card-title class="text-center justify-center">Veuillez patienter</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          <v-progress-circular indeterminate color="EoleYellow"></v-progress-circular>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text class="text-center">Vérification du paiement en cours...</v-card-text>
                  </div>
                  <div v-else-if="isRecognized">
                    <v-card-title class="text-center justify-center">Paiement accepté</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          <i class="fa-solid fa-circle-check EoleGreen--text fa-8x"></i>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text class="text-center">
                      Votre paiement a été effectué avec succès. Vous recevrez un email de confirmation dans quelques
                      minutes.
                    </v-card-text>
                    <v-card-text class="text-center"> Anavel vous remercie pour votre confiance. </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions class="mt-10 text-center mx-auto">
                      <v-btn text @click="goBack">Retour</v-btn>
                    </v-card-actions>
                  </div>
                  <div v-else>
                    <v-card-title class="text-center justify-center">Aucun paiement reconnu</v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          <i class="fa-solid fa-circle-xmark EoleRed--text fa-8x"></i>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text class="text-center">
                      Aucun paiement n'a été reconnu pour cette session. Veuillez contacter le support pour plus
                      d'informations.
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions class="mt-10 text-center mx-auto">
                      <v-btn text @click="goBack">Retour</v-btn>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from "@/views/config/config"

export default {
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    siret: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expired: false,
      isLoading: true,
      isRecognized: false,
    }
  },
  async created() {
    try {
      const response = await fetch(`${config.apiUri}/due/plan/payment-success?session_id=${this.sessionId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.ok) {
        this.isRecognized = true

        //redirect to the main due items page
        setTimeout(() => {
          this.$router.push(`/services/due`)
        }, 5000)
      } else if (response.status === 400) {
        this.expired = true
      } else {
        console.error("Erreur lors de la génération du document.")
      }
    } catch (error) {
      console.error("Erreur lors de la génération du document:", error)
    }

    this.isLoading = false
  },
}
</script>
